.nft_detail_container_skeleton {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: var(--layout-pad);
}
.nft_details_skeleton {
  display: flex;
  flex-wrap: wrap;
  row-gap: 40px;
  column-gap: 40px;
  padding: 24px;
}
.nft_details_skeleton > .img_container_skeleton {
  flex: 0.8;
  border-radius: var(--card-border-radius);
}
.img_section {
  min-width: 320px;
  max-width: 500px;

  min-height: 400px;
  max-height: 300px;
}
.header_top_skeleton {
  width: 100px;
  height: 25px;
}
.title_skeleton {
  width: 100%;
  height: 40px;
}
.subtitle_skeleton {
  width: 50%;
  height: 25px;
}
.nft_details_skeleton > .content_container_skeleton {
  padding: 17px 0;
  flex: 1;
}
.content_container_skeleton {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.header_skeleton {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.header_skeleton_title {
  width: 100%;
  display: flex;
  row-gap: 20px;
  flex-direction: column;
}
.header_skeleton_trait {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

  column-gap: 05px;
  row-gap: 05px;
}
.trait_box {
  width: 100%;
  height: 50px;
}
.create_section_skeleton {
  width: 100%;
  height: 60px;
}
@media screen and (max-width: 768px) {
  .nft_detail_container_skeleton {
    width: 100%;
    row-gap: var(--layout-md-row-gap);
    padding:0;
  }
  .nft_details_skeleton {
    display: flex;

    flex-direction: column;
  }
  .nft_details_skeleton > .img_container_skeleton {
    flex: 0.8;
    border-radius: var(--card-border-radius);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .img_section {
    /* min-width: 300px; */
    width: 100%;

    min-height: 300px;
  }
  .header_top_skeleton {
    width: 80px;
    height: 30px;
  }
  .title_skeleton {
    width: 100%;
    height: 40px;
  }
  .subtitle_skeleton {
    width: 50%;
    height: 25px;
  }
  .nft_details_skeleton > .content_container_skeleton {
    padding: 17px 0;
    flex: 1;
  }
  .content_container_skeleton {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
  .header_skeleton {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .header_skeleton_title {
    width: 100%;
    display: flex;
    row-gap: 20px;
    flex-direction: column;
  }

  .create_section_skeleton {
    width: 100%;
    height: 50px;
  }
}
