.rank_box {

  font-family: "Red Hat Display", sans-serif;
  font-size: 11px;
  font-weight: 900;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: var(--body-bg-color);
  width: 70px;
  height: 23px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 3px;
}
.rank_box > .text {
  display: block;
  font-weight: 900;
  
}
.rank_box.silver {
  background: linear-gradient(180deg, #b5b5b5 0%, #e7e7e7 100%);
}
.rank_box.gold {
  background: linear-gradient(180deg, #f8f452 0%, #fdfa9e 100%);
}
.rank_box.bronze {
  background: linear-gradient(180deg, #eb913e 0%, #fdcca4 100%);
}

@media screen and (max-width: 768px) {
  .rank_box {
   
    width: 55px;
    height: 18px;

   
    border-radius: 2px;
    
  }
  .rank_box > .text {
    
    font-size: 06px;
    font-weight: 600;
    
  }
}

@media screen and (max-width:600px) {
  .rank_box {
   
    width: 35px;
    height: 15px;

   
    
    
  }
  .rank_box > .text {
    

   
    border-radius: 2px;
    
  }
}