.page_header_container {
  display: flex;
  align-items: center;
  background-color: var(--btn-bg-color);
  justify-content: center;
  padding: 8px;
  width: 100%;
  height: var(--page-header-height);
  border-radius: var(--border-radius);
}
.text_container{
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.page_header {
  text-align: center;
  font-size: var(--page-header-font-size);
  font-weight: 700;
  line-height: 0.8;
  word-wrap: break-word;
  word-break: break-all;

  text-transform: uppercase;
  color: #000;
}

.sup_img {
  width: 16px;
  height: 16px;
}
@media screen and (max-width: 768px) {
  .page_header_container {
    border-radius: var(--border-md-radius);
    height: var(--page-md-header-height);
  }
  .page_header {
    font-size: var(--page-md-header-font-size);
    font-weight: 600;
  }
  .sup_img {
    width: 12px;
    height: 12px;
  }
}
