.popup_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--light-bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  backdrop-filter: blur(02px);

}
.loader_container{
    padding-top: 30px;
    height: 180px;
    
}
.box_container {
  max-width: var(--popup-card-width);
  width: 100%;
  /* max-height: var(--popup-card-height);
    height: 100%; */
  background-color: var(--body-bg-color);
  display: flex;
  flex-direction: column;
  row-gap: var(--popup-card-pad);
  align-items: center;
  justify-content: center;
  border-radius: var(--card-border-radius);
  border: var(--std-border-size) solid var(--border-color);
  padding: var(--popup-card-pad);
}
.construction_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
.construction_container > h2 {
  font-size: var(--popup-heading-font-size);
  font-weight: 700;
  line-height: 70px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-bottom: 1px solid var(--card-disc-color);
}
.construction_container > h4 {
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 1px;
  text-align: left;
  text-transform: uppercase;
}
.construction_container > ul {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  margin-left: 30px;
}
.construction_container > ul > .list {
  list-style-type: disc;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 1px;
  text-align: left;
  text-transform: uppercase;
}
.close_btn{
  padding: 10px 20px;
  background: var(--body-text-color);
  font-size: 14px;
  font-weight: 900;
  line-height: 19px;
  text-align: center;
  outline: none;
  border: none;
  border-radius: var(--card-btn-border-radius);
  text-transform: uppercase;
  cursor: pointer;
}
.close_btn.disable{
  background: var(--staking-btn-bg-color);
}
@media screen and (max-width:768px) {


  .box_container {
    margin: 0 20px;
    background-color: var(--body-bg-color);
    display: flex;
    flex-direction: column;
    row-gap: var(--popup-md-card-pad);
    align-items: center;
    justify-content: center;
    border-radius: var(--card-md-border-radius);
    border: var(--std-border-size) solid var(--border-color);
    padding: var(--popup-md-card-pad);
  }
  .construction_container {
    padding-top: 40px;
    row-gap: 10px;
  }
  .construction_container > h2 {
    font-size: var(--popup-md-heading-font-size);
    font-weight: 600;
    line-height: var(--popup-heading-font-size);
    letter-spacing: 0px;
   
  }
  .construction_container > h4 {
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: 0px;
  }
  .construction_container > ul {
    display: flex;
    flex-direction: column;
    row-gap: 3px;
    margin-left: 30px;
  }
  .construction_container > ul > .list {
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: 0px;
  }
  .loader_container{
    width: 90px;
    height: 130px;
  }
}