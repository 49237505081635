.single_leaderboard {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  border-radius: var(--card-btn-border-radius);
  padding: 18px;
  background: var(--leaderboard-bg-color);
  cursor: pointer;
  transition: 0.3s;
  width: 100%;
}
.single_leaderboard.active{
  border: 1px solid var(--border-color);
}
.single_leaderboard:hover {
  background: var(--hover-bg-color);
}
.single_leaderboard.current {
  border: var(--box-border-size) solid var(--border-color);
}
.single_leaderboard > .position {
  flex: 0.3;
  max-width: 100%;
  min-width: 60px;
  word-break: break-all;
  font-size: var(--leaderborad-title-span-font-size);
  font-weight: 600;
  line-height: var(--line-height);
  text-transform: uppercase;
  color: var(--body-text-color);
  text-align: center;
}
.single_leaderboard > .position > span {
  max-width: 85px;
  display: block;
  width: 100%;
}

.single_leaderboard > .rank_container {
  flex: 0.5;
  max-width: 100%;
  min-width: 60px;
  word-break: break-all;

  /* padding-left: 5px; */
}

.single_leaderboard > .wallet_address {
  display: flex !important;
  column-gap: 10px !important;
  flex: 1;
  max-width: 100%;
  min-width: 60px;
  word-break: break-all;

  font-size: var(--leaderborad-title-span-font-size);
  font-weight: 600;
  word-wrap: break-word;
  word-break: break-all;
  line-height: var(--line-height);
  text-transform: uppercase;
  display: flex;
  align-items: center;

  column-gap: 10px;
  color: var(--body-text-color);
}
.single_leaderboard > .wallet_address > .text {
  display: block;
  min-width: 57px;
  max-width: 120px;
  width: 100%;
  
 }
.single_leaderboard > .wallet_address > .wale_icon > img {
  width: 26px;
  height: 21px;
}
.single_leaderboard > .points {
  max-width: 100%;
  min-width: 60px;
  word-break: break-all;
  flex: 0.3;

  text-align: right;
  font-size: var(--leaderborad-title-span-font-size);
  font-weight: 600;
  line-height: var(--line-height);
  text-transform: uppercase;
  color: var(--body-text-color);
}

@media screen and (max-width: 768px) {
  .single_leaderboard {
    width: 100%;

    column-gap: 05px;
    border-radius: var(--card-md-btn-border-radius);
    padding: var(--board-md-padding);
  }
  .single_leaderboard > .position {

    font-size: var(--leaderborad-title-md-span-font-size);
    font-weight: 400;
  }
 

  .single_leaderboard > .position > span {
    max-width: 57px;
    display: block;
    width: 100%;
  }


  .single_leaderboard > .wallet_address > .wale_icon > img {
    width: 25px;
  }
  .single_leaderboard > .wallet_address {
    font-size: var(--leaderborad-title-md-span-font-size);
    font-weight: 400;
  }
  .single_leaderboard > .points {

    font-size: var(--leaderborad-title-md-span-font-size);

    font-weight: 400;
  }
}

@media screen and (max-width: 600px) {
  .single_leaderboard {
    width: 100%;
    column-gap: 05px;
    border-radius: var(--card-sm-btn-border-radius);
   
  }
  .single_leaderboard > .position {
    font-size: var(--title-sm-span-font-size);
    font-weight: 400;
  }
  .single_leaderboard > .wallet_address > span {
  display: block;
  
 }

  .single_leaderboard > .position > span {
    max-width: 57px;
    display: block;
    font-size: var(--title-sm-span-font-size);
    width: 100%;
  }

  .single_leaderboard > .wallet_address > .wale_icon > img {
    width: 15px;
    height: 15px;
  }
  .single_leaderboard > .wallet_address {
    font-size: var(--title-sm-span-font-size);
    font-weight: 400;
  }
  .single_leaderboard > .wallet_address> .text {
    font-size: var(--title-sm-span-font-size);
    font-weight: 400;
    line-height: 1;
    width: 0%;
  }
  .single_leaderboard > .points >span{
    font-size: var(--title-sm-span-font-size);
    font-weight: 400;
    text-align:left;
  }
  
}