.my_staking_container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: var(--layout-row-gap);
  padding: var(--layout-pad);
}
.staking_stats_container {
  padding: var(--layout-sub-pad);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  column-gap: 40px;
  row-gap: 20px;
  width: 100%;
}
.staking_stats_container > .stakes_stats{
  display: flex;
  flex-wrap: wrap;

  column-gap: 40px;
  row-gap: 20px;

}
.staking_stats_container > .stakes_stats> .stats {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.staking_stats_container>.stakes_stats > .stats > .points {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--body-text-color);
}
.staking_stats_container >.stakes_stats> .stats > .disc {
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--card-disc-color);
  text-transform: uppercase;
}
.staking_stats_container > .stake_btn {
  /* height: 0%; */
  padding: 15px 25px;
  background: var(--staking-btn-bg-color);
  font-size: 14px;
  font-weight: 900;
  line-height: 19px;

  text-align: center;
  outline: none;
  border: none;
  border-radius: var(--border-radius);
  text-transform: uppercase;
  cursor: pointer;
}
.staking_stats_container > .stake_btn.white {
  background-color: var(--body-text-color);
}
.staking_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(268px, 1fr));
  align-items: center;
  justify-content: center;
  padding: var(--layout-sub-pad);
  column-gap: var(--btw-card-gap);
  row-gap: var(--btw-card-gap);
}

@media screen and (max-width: 768px) {
  
  .my_staking_container {
    padding: var(--layout-md-pad);
    row-gap: var(--layout-md-row-gap);
  }
  .staking_stats_container {
    flex-direction: column;
    padding: 0px;
    align-items: flex-start;
    row-gap: 10px;
    
  }
  .staking_stats_container > .stakes_stats{
    justify-content: space-between;

  }
  .staking_stats_container > .stakes_stats> .stats {
    text-align: justify;
    row-gap: 05px;
  }
  .staking_stats_container>.stakes_stats > .stats > .points {
    font-size: 15px;
    font-weight: 500;
   
  }
  .staking_stats_container >.stakes_stats> .stats > .disc {
    font-size: 10px;
    font-weight: 500;
   
  }
  .staking_stats_container > .stake_btn {
    width: 100%;
    padding: 10px;
    background: var(--staking-btn-bg-color);
    font-size: 14px;
    font-weight: 900;
    line-height: 19px;
  
    text-align: center;
    outline: none;
    border: none;
    border-radius: var(--border-radius);
    text-transform: uppercase;
    cursor: pointer;
  }
  
  .staking_container {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

    /* padding: 0; */
    row-gap: var(--btw-md-card-gap);
    column-gap: var(--btw-md-card-gap);
  }
}
