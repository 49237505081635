@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500&display=swap');
:root {

  --screen-lg-size:912px;
  --layout-margin: 40px;
  --layout-pad: 30px;
  --layout-md-pad: 20px;
  --layout-row-gap: 20px;
  --layout-right-size: 1300px;
  --page-header-height: 69px;
  --page-md-header-height: 50px;
  --page-header-font-size:40px;
  --page-md-header-font-size: 25px;
  --total-points-width: 130px;
  --total-md-points-width: 95px;
  --total-points-height: 28px;
  --total-md-points-height: 20px;
  --total-points-pad:5px;
  --btw-card-gap: 15px;
  --btw-md-card-gap: 10px;
  --layout-md-row-gap: 10px;
  --body-bg-color: rgba(0, 0, 0, 1);
  --light-bg-color: rgba(0, 0, 0, 0.7);
  --body-text-color: rgba(255, 255, 255, 1);
  --side-menu-width: 400px;
  --side-menu-bg-color: rgba(0, 0, 0, 1);
  --side-menu-pad: 21px;
  --border-color: rgba(199, 246, 54, 1);
  --hover-bg-color: rgba(16, 16, 16, 0.8);
  --menu-list-bg-color: rgba(16, 16, 16,1);
  --menu-text-size: 14px;
  --menu-md-text-size: 12px;
  --side-bar-footer-top-marign:16px;
  --btn-bg-color: rgba(199, 246, 54, 1);
  --menu-border-radius: 8px;
  --menu-md-border-radius: 5px;
  --btn-pad: 13px;
  --btn-md-pad: 6px;
  --btn-font-size: 24px;
  --btn-md-font-size: 16px;
  --card-border-radius: 8px;
  --card-md-border-radius: 5px;
  --card-row-gap: 13px;
  --card-md-row-gap: 10px;
  --card-pad: 18px;
  --card-md-pad: 08px;
  --card-img-height: 270px;
  --card-md-img-height: 220px;
  --card-heading-font-size: 20px;
  --card-md-heading-font-size: 16px;
  --card-disc-font-size: 14px;
  --card-md-disc-font-size: 12px;
  --card-md-font-weight: 600;
  --card--font-weight: 700;
  --card-disc-color: #505050;
  --staking-btn-bg-color: #505050;
  
  --layout-sub-pad: 24px;
  --leaderboard-bg-color: rgba(16, 16, 16, 1);
  --card-btn-border: 3px;
  --stake-now-btn-color: #fff;
  --card-btn-pad: 4px 20px;
  --card-md-btn-pad: 2px 6px;
  --board-padding: 15px;
  --board-md-padding: 07px;
  --card-btn-border-radius: 5px;
  --card-md-btn-border-radius: 3px;
  --card-btn-font-size: 11px;
  --card-md-btn-font-size: 08px;
  --detail-heading-nft-font-size: 12px;
  --detail-heading-md-nft-font-size: 10px;
  --line-height: 21px;
  --line-md-height: 10px;
  --title-font-size: 40px;
  --title-md-font-size: 20px;
  --title-span-font-size: 24px;
  --title-md-span-font-size: 16px;
  --title-sm-span-font-size: 08px;
  --leaderborad-title-span-font-size: 16px;
  --leaderborad-title-md-span-font-size: 12px;
  --title-p-font-size: 14px;
  --title-md-p-font-size: 09px;
  --std-border-size: 2px;
  --box-border-size: 1px;
  --border-radius: 8px;
  --border-md-radius: 5px;
  --headline-pad: 15px;
  --headline-md-pad: 12px;
  --points-pad: 10px;
  --md-points-pad: 07px;
  --header-img-width: 258px;
  --header-md-img-width: 150px;
  --header-img-height: 38px;
  --header-md-img-height: 25px;
  --popup-card-width: 579px;
  --popup-card-height: 558px;
  --popup-card-pad: 35px;
  --popup-md-card-pad: 20px;
  --popup-heading-font-size: 40px;
  --popup-md-heading-font-size: 20px;
  --progress-pad: 18px;
  --progress-md-pad: 12px;

  --md-screen-size: 768px;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
#root {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: var(--layout-margin);
  overflow-x: hidden;
}
img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: var(--body-text-color);
}
.light_weight {
  font-weight: 400 !important;
}
.status_icon{
  margin: auto;
  width: 150px;
  height:150px;
}
body {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  min-height: 100vh;

  margin: 0;
  font-family: 'Red Hat Display', sans-serif;
  background-color: var(--body-bg-color);
  color: var(--body-text-color);
  transition: all 0.3s linear;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::-webkit-scrollbar {
  display: none; 
}
.icon-style {
  font-weight: 800;
  font-size: 15px;
}
.icon-styles {
  font-size: 20px;
}
.page_loader_contianer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  backdrop-filter: blur(02px);
  z-index: 9;
  background-color: var(--light-bg-color);
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 768px) {

  .icon-style {
    font-size: 12px;
  }
  .icon-styles {
    font-size: 15px;
  }
  #root {
    padding: 10px;
    height: 100vh;
  }
}

