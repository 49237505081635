.nft_detail_container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 30px;
}
.nft_details {
  display: flex;
  flex-wrap: wrap;
  row-gap: 40px;
  column-gap: 40px;
  padding: 24px;
}
.nft_details > .img_container {
  flex: 0.8;
  border-radius: var(--card-border-radius);
  overflow: hidden;
  /* height: 500px; */
}
.nft_details > .img_container > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.nft_details > .content_container {
  padding: 17px 0;
  flex: 1;
}
.content_container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.detail_header_container {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.detail_header_container > .detail_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detail_header_container > .detail_header > a > h4 {
  font-weight: 700;
  font-size: var(--title-md-span-font-size);
  line-height: var(--line-height);
  /* text-transform: uppercase; */
  color: var(--border-color);
}
.status {
  outline: none;
  border: none;
  color: var(--body-bg-color) !important;

  padding: var(--card-btn-pad);
  font-size: var(--card-btn-font-size);
  font-weight: 800;
  text-transform: uppercase;
  border-radius: var(--card-btn-border-radius);
  cursor: pointer;
}
.status.yellow {
  background: var(--border-color) !important;
}
.status.white {
  background-color: var(--body-text-color) !important;
}
.title {
  display: flex;
  flex-direction: column;
}
.title > h3 {
  font-size: var(--title-font-size);
  font-weight: 700;
  line-height: 1.2;
  /* text-transform: uppercase; */
  line-height: 52.92px;
  color: var(--body-text-color);
}
.title > span {
  font-size: var(--title-span-font-size);
  font-weight: 600;
  line-height:21px;
  text-transform: uppercase;
  letter-spacing: 1px;

  color: var(--body-text-color);
}
.create_section {
  display: flex;
  position: relative;
  justify-content: space-between;
  /* align-items: center; */
  border: var(--std-border-size) solid var(--border-color);
  border-radius: var(--border-radius);
  cursor: pointer;
  overflow: hidden;
}
.create_section >.token_section{
  flex: 0.9;
  padding-left: var(--headline-pad);
  display: flex;
  align-items: center;
  font-size: var(--title-p-font-size);
  text-transform: uppercase;
  color: var(--border-color);
  word-wrap: break-word;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s;

}

.create_section > .token_section:hover{
  background-color: var(--border-color);
  color: var(--body-bg-color);
}

/* .create_section_token {
  display: flex;
  position: relative;
  justify-content: space-between;
  padding: var(--headline-pad);
  border: var(--std-border-size) solid var(--border-color);
  border-radius: var(--border-radius);
  overflow: hidden;
}
.create_section_token > p {
  flex: 0.8;
  display: flex;
  align-items: center;
  font-size: var(--title-p-font-size);
  text-transform: uppercase;
  color: var(--border-color);
  word-wrap: break-word;
  font-weight: 600;
  cursor: pointer;
} */

.create_section > .points {
  flex:0.2;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  column-gap: 2px;
  background-color: var(--border-color);
  color: #000;
  padding: var(--points-pad);
  overflow: hidden;
}
.create_section > .copy_points{
  flex:0.2;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 2px;
  width: 75px;
  background-color: var(--border-color);
  color: #000;
  padding: var(--points-pad);
}
.popup{
  position: absolute;
  bottom: -25px;
  right: 0;
  color: white;
}
.create_section > .points > span {
  font-size: 17px;
  line-height: var(--line-height);
  font-weight: 900;
}
.trait_container {
  display: flex;
  flex-direction: column;
  row-gap: 07px;
}
.trait_container > h4 {
  font-size: var(--card-heading-font-size);
  font-weight: 700;
  line-height: var(--line-height);
  text-transform: capitalize;
  color: var(--card-disc-color);
}
.rarity_container {
  display: grid;
  grid-template-columns: repeat(3,1fr);
  column-gap: 07px;
  row-gap: 07px;
}
.rarity_container > .rarity_box {
  flex: 1;
  border: var(--box-border-size) solid #cfdbd5;
  border-radius: var(--border-radius) !important;
  padding: 05px 15px !important;
  display: flex;
  flex-direction: column;
  row-gap: 05px;
}
.rairt_box_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rairt_box_header > .text {
  font-size: var(--title-p-font-size);
  font-weight: 400;
  line-height: 18.52px;
  text-transform: capitalize;
  color: #909090;
}
.rarity-disc > span {
  font-size: 16px;
  font-weight: 400;
  line-height: var(--line-height);
  text-transform: capitalize;
  color: var(--body-text-color);
}
.rarity-disc {
  padding: 0;
}
.rarity_container > .rarity_box.active {
  border-color: var(--border-color);
}

@media screen and (max-width: 768px) {
  .nft_detail_container {
    row-gap: var(--layout-md-row-gap);
    padding: var(--layout-md-pad);
  }
  .create_section > .token_section:hover{
    background-color: var(--body-bg-color);
    color: var(--border-color);
   
  }
  .nft_details {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 20px;
    padding: 0;
  }
  .nft_details > .img_container {
    flex: 0.5;
    border-radius: var(--card-md-border-radius);
    /* max-width: 170px; */
    width: 100%;
    margin: auto;
    padding: var(--progress-md-pad) 0;
    /* max-height:170px ; */
  }

  .nft_details > .content_container {
    padding: 5px 0;
    flex: 1;
  }
  .content_container {
    row-gap: var(--layout-md-row-gap);
  }
  .detail_header_container {
    row-gap: 05px;
  }

  .detail_header_container > .detail_header > h4 {
    font-weight: 600;
    font-size: var(--detail-heading-md-nft-font-size);
  }
  .detail_header_container > .detail_header > .status {
    /* padding: var(--card-md-btn-pad); */
    font-size: var(--card-md-btn-font-size);
    font-weight: var(--card-md-font-weight);
    border-radius: var(--card-md-btn-border-radius);
  }

  .title > h3 {
    font-size: var(--title-md-font-size);
    font-weight: 600;
  }
  .title > span {
    font-size: var(--title-md-span-font-size);
    font-weight: 600;
  }
  .create_section {
    overflow: hidden;
    padding-left: var(--headline-md-pad);
    border: var(--box-border-size) solid var(--border-color);
    border-radius: var(--border-radius);
  }
  .create_section >.token_section{
    padding-left: 5px;
  }
  
  .create_section >.token_section > p {
    font-size: var(--title-md-p-font-size);
    word-wrap: break-word;
    word-break: break-all;
    font-weight: 500;
  }
  .create_section > .points {
    padding: var(--md-points-pad);
  }
  .create_section > .points > span {
    font-size: 12px;
    line-height: var(--line-height);
    font-weight: 600;
  }
  .trait_container {
    row-gap: 05px;
  }
  .trait_container > h4 {
    font-size: var(--card-md-heading-font-size);
    font-weight: 600;
  }
  .rarity_container {
    column-gap: 05px;
    row-gap: 05px;
  grid-template-columns: repeat(2,1fr);

  }
  .rarity_container > .rarity_box {
    border-radius: var(--border-md-radius) !important;
    padding: 5px var(--headline-md-pad) !important;

    row-gap: 0;
  }

  .rairt_box_header > .text {
    font-size: 12px;
    font-weight: 300;
    
  }
  .rarity-disc > span {
    font-size: 14px;
    font-weight: 300;
    line-height: var(--line-md-height);
  }
  .point_img{
    width: 11px;
  }
}
