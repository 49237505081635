.alert_container {
  background: var(--leaderboard-bg-color);
  padding: 10px;
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: 100%;
}
.alert_container > .alet-icon {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #505050;
  border-radius: var(--border-md-radius);
  color: #505050;
  font-size: 12px;
}
.alert_container > .text {
  display: flex;
  align-items: center;
  font-size: var(--title-p-font-size);
  font-weight: 600;
  text-transform: uppercase;
  color: #505050;
}
.alert_container > .textlower {
  display: flex;
  align-items: center;
  font-size: var(--title-p-font-size);
  font-weight: 600;
  color: #505050;
}
@media screen and (max-width: 768px) {
  .alert_container {
    padding: 5px;
    border-radius: var(--border-md-radius);
  }
  .alert_container > .alet-icon {
    width: 20px;
    height: 20px;
    font-size: 10px;
  }
  .alert_container > .text {
    font-size: var(--title-md-p-font-size);

  }
}
