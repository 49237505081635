.app {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;

 
  border-radius: var(--card-border-radius);
  overflow: hidden;
  border: var(--std-border-size) solid var(--border-color);
}
.main_container{
   width: 100%;

 
 position: relative;
 overflow-y: scroll;

}
.content-container {
 position: relative;
  
  /* left: calc(var(--side-menu-width)/ 2); */
  max-width: var(--layout-right-size);
  margin: 0 auto;
  /* overflow-x: hidden; */
  width: 100%;


  /* width: calc((100% - var(--side-menu-width))); */
}
.header{
  padding: var(--layout-pad);
  position: sticky;
  top: 0;
  row-gap: 10px;
  z-index: 9;
  background-color: var(--body-bg-color);
  display: none;
}
.hamburger{
  cursor: pointer;
  width: 40px;
  height: 40px;
  background: var(--leaderboard-bg-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--menu-border-radius);
}
.hamburger-icon{
  font-size: 30px;
}
.header-brand{
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-brand >img{
  width: var(--header-md-img-width);
  height: var(--header-md-img-height);
}

@media screen and (max-width: 768px) {
  /* .app {
    border: none;
  } */
  .content-container {
    left: 0;
    width: 100%;
  }
  .header{
    padding: var(--layout-md-pad);
    display: flex;
    align-items: center;
  }
}
