.account_Tap {
  position: absolute;
    /* width: 123.68px; */
  cursor: pointer;

  padding: 2px 7px;

  height: 22px;
  border-radius: var(--menu-md-border-radius);

  display: flex;
  column-gap: 05px;
  align-items: center;
  justify-content: center;
  right: 0;
  margin-top: 10px;
  margin-right: 10px;
}
.account_Tap > .text {
  font-size: 12px;

  font-weight: 900;
  color: var(--body-bg-color);
  text-transform: uppercase;
}
.account_Tap.white {
  background-color: var(--body-text-color);
}
.account_Tap.yellow {
  background-color: var(--border-color);
}
