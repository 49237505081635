input[type="checkbox"] {
  display: none;
  margin-top: 10px;
  margin-left: 15px;
}

/* Define the custom checkbox style */
.custom-checkbox {
  margin-top: 10px;

  margin-left: 15px;

  display: inline-block;
  width: 27px;
  height: 27px;
  background-color: var(--leaderboard-bg-color);
  border-radius: var(--card-btn-border-radius);
  border: var(--box-border-size) solid var(--border-color);
  cursor: pointer;
}
.custom-checkbox:hover {
  background-color: var(--border-color);
}

/* Style the checked state */
input[type="checkbox"]:checked + .custom-checkbox {
  background-image: url("/src/assests/checkbox_icon.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-color: var(--border-color);
  color: #fff; /* Change text color to white when checked */
}

/* Style the label text */
.checkbox-label {
  margin-left: 8px;
}

@media screen and (max-width: 768px) {
  /* Define the custom checkbox style */
  .custom-checkbox {
    margin-left: 10px;

    display: inline-block;
    width: 20px;
    height: 20px;

    border-radius: 2px;
  }
  .custom-checkbox:hover {
    background-color: var(--leaderboard-bg-color);
  }
}
