.leaderboard_container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: var(--layout-pad);
  padding: var(--layout-pad);
}
.board_container {
  display: flex;
  flex-direction: column;
  column-gap: 15px;
  row-gap: 15px;
}
.leaderboard_header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  border: var(--box-border-size) solid var(--body-text-color);
  border-radius: var(--card-btn-border-radius);
  padding: 18px;
  width: 100%;

  background: var(--leaderboard-bg-color);
}
.leaderboard_header > .position {
  flex: 0.3;
  max-width: 100%;
  min-width: 60px;
  word-break: break-all;
  font-size: var(--leaderborad-title-span-font-size);
  font-weight: 700;
  line-height: var(--line-height);
  text-transform: uppercase;
  color: var(--body-text-color);
}
.leaderboard_header > .rank {
  flex: 0.5;
  max-width: 100%;
  min-width: 60px;
  word-break: break-all;
  text-align: left;
  font-size: var(--leaderborad-title-span-font-size);
  font-weight: 700;
  line-height: var(--line-height);
  text-transform: uppercase;
  color: var(--body-text-color);
}
.leaderboard_header > .wallet_address {
  flex: 1;

  max-width: 100%;
  min-width: 60px;

  word-break: break-all;
  font-size: var(--leaderborad-title-span-font-size);
  font-weight: 700;
  word-break: break-all;
  word-wrap: break-word;
  line-height: var(--line-height);
  text-transform: uppercase;
  color: var(--body-text-color);
}
.leaderboard_header > .points {
  flex: 0.3;
  text-align: right;
  max-width: 100%;
  min-width: 60px;

  word-break: break-all;
  font-size: var(--leaderborad-title-span-font-size);
  font-weight: 700;
  line-height: var(--line-height);
  text-transform: uppercase;
  color: var(--body-text-color);
}
.page_loader_contianer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--light-bg-color);
  display: flex;
  align-items: center;
  justify-content: center;
}
.board_container {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  /* padding: 15px 0; */
}
.dots {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
}
.dots > span {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: var(--body-text-color);
}
@media screen and (max-width: 768px) {
  .leaderboard_container {
    row-gap: var(--layout-md-row-gap);
    padding: var(--layout-md-pad);
  }
  .board_container {
    column-gap: var(--layout-md-row-gap);
    row-gap: var(--layout-md-row-gap);
  }
  .leaderboard_header {
    column-gap: 05px;
    border-radius: var(--card-md-btn-border-radius);
    padding: var(--board-md-padding);
  }

  .leaderboard_header > .position {
    font-size: var(--leaderborad-title-md-span-font-size);
    font-weight: 500;
  }
  .leaderboard_header > .rank {
    font-size: var(--leaderborad-title-md-span-font-size);
    font-weight: 500;
  }
  .leaderboard_header > .wallet_address {
    font-size: var(--leaderborad-title-md-span-font-size);

    font-weight: 500;
  }
  .leaderboard_header > .points {
    font-size: var(--leaderborad-title-md-span-font-size);

    font-weight: 500;
  }
  .board_container {
    row-gap: 05px;
    /* padding: 15px 0; */
  }
  .dots {
    column-gap: 3px;
  }
  .dots > span {
    width: 5px;
    height: 5px;
  }
}
