.lds-spinner {
  display: inline-block;
  position: relative;
  width: 120px;
  height: 180px;
}
.lds-spinner div {
  transform-origin: 45px 90px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 18px;
  height: 50px;
  border-radius: 30px;
  background: var(--border-color);
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2)::after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 18px;
  height: 35px;
  border-radius: 30px;
  background: var(--border-color);
}
.lds-spinner div:nth-child(2) {
  transform-origin: 27px 85px;

  transform: rotate(45deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3)::after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 18px;
  height: 33px;
  border-radius: 30px;
  background: var(--border-color);
}
.lds-spinner div:nth-child(3) {
  transform-origin: 35px 81px;
  transform: rotate(90deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4)::after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 18px;
  height: 28px;
  border-radius: 30px;
  background: var(--border-color);
}
.lds-spinner div:nth-child(4) {
  transform-origin: 40px 80px;

  transform: rotate(135deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(180deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(225deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(270deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8)::after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 18px;
  height: 40px;
  border-radius: 30px;
  background: var(--border-color);
}
.lds-spinner div:nth-child(8) {
  transform: rotate(315deg);
  animation-delay: -0.4s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media screen and (max-width: 768px) {
  .lds-spinner {
    width: 70px;
    height: 130px;
  }
  .lds-spinner div {
    transform-origin: 44px 65px;   
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 12px;
    height: 35px;
    border-radius: 30px;
    background: var(--border-color);
  }
  
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2)::after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 12px;
    
    height: 25px;
    border-radius: 30px;
    background: var(--border-color);
  }
  .lds-spinner div:nth-child(2) {
    
    transform-origin: 35px 65px;
  
    transform: rotate(45deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3)::after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 12px;

    height: 23px;
    border-radius: 30px;
    background: var(--border-color);
  }
  .lds-spinner div:nth-child(3) {
    transform-origin: 38px 63px;

    
    transform: rotate(90deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4)::after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 12px;

    height: 22px;
    border-radius: 30px;
    background: var(--border-color);
  }
  .lds-spinner div:nth-child(4) {
   
    transform-origin: 42px 62px;

  
    transform: rotate(135deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(180deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(225deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(270deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8)::after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 12px;

    height: 30px;

    border-radius: 30px;
    background: var(--border-color);
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(315deg);
    animation-delay: -0.4s;
  }
  

}
